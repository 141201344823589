// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--EWyeN";
export var label = "style-module--label--2TRIO";
export var ctBlack = "style-module--ctBlack--2LAed";
export var ctSmall = "style-module--ctSmall--2n6aD";
export var title = "style-module--title--XglkK";
export var ctMedium = "style-module--ctMedium--beVU1";
export var ctLarge = "style-module--ctLarge--2Kr6h";
export var ctXLarge = "style-module--ctXLarge--gViPu";
export var ctIntro = "style-module--ctIntro--3yTko";
export var ctBody = "style-module--ctBody--3TlFw";
export var ctBodyMedium = "style-module--ctBodyMedium--26Soz";
export var ctBodyBold = "style-module--ctBodyBold--1IxoZ";
export var ctLink = "style-module--ctLink--1rTWP";
export var frutiLarge = "style-module--frutiLarge--3Je3-";
export var ctUi = "style-module--ctUi--170JP";
export var ctMeta = "style-module--ctMeta--1ukxs";
export var ctMicro = "style-module--ctMicro--203rx";
export var ctMediumItalic = "style-module--ctMediumItalic--_6LB9";
export var link = "style-module--link--4V0EU";
export var infographics = "style-module--infographics--1WnYg";
export var image = "style-module--image--2_Aim";