import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import uniqid from 'uniqid'
import Seo from '~components/globals/Seo'
import SingleArticleTeaser from '~components/globals/SingleArticleTeaser'
import Container from '~components/globals/Container'
import * as styles from './style.module.scss'

export const Format = ({
  pageContext: {
    prismicCuratedNewsTeaser,
    prismicInterviewTeaser,
    prismicInfographicTeaser,
    interviews,
    infographics,
    insights,
    allPrismicPage
  },
  location
}) => {
  const [data, setData] = useState(null)
  const [type, setType] = useState(null)
  const [pageInfo, setPageInfo] = useState(null)
  const [filteredArticles, setFilteredArticles] = useState([])

  useEffect(() => {
    {insights && setType('insights')}
    {interviews && setType('interviews')}
    {infographics && setType('infographics')}

    if (interviews?.length) {
      setFilteredArticles(interviews)
      setData(prismicInterviewTeaser)
    }
    if (infographics?.length) {
      setFilteredArticles(infographics)
      setData(prismicInfographicTeaser)
    }
    if (insights?.length) {
      setFilteredArticles(insights)
      setData(prismicCuratedNewsTeaser)
    }
  }, [])

  useEffect(() => {
    if (type) {
      const page = allPrismicPage.edges.filter(edge => edge.node.data.slug === type)
      setPageInfo(page[0].node.data)
    }
  }, [type])

  return (
    <Container extraClassName={styles.Topic}>
      <Seo
        title={pageInfo?.sharing_title.text}
        description={pageInfo?.sharing_description.text}
        url={location.href}
        image={pageInfo?.sharing_image.url}
      />
      {data && (
        <div className={styles.header}>
          <div className={styles.image}>
            <img src={data.image.url} alt={data.image.alt} />
          </div>
          <div className={styles.headerText}>
            <h4 className={styles.eyebrow}>
              {data.eyebrow?.text}
            </h4>
            <h2 className={styles.title}>
              {data.subline ? parse(data.subline.html) : parse(data.subheadline.html)}
            </h2>
          </div>
        </div>
      )}
      <div className={styles.container}>
        {filteredArticles.length && filteredArticles.map(article => (
          <SingleArticleTeaser
            data={article.data}
            key={uniqid()}
            uid={article.uid}
            type={type ? type : null}
          />
        ))}
      </div>
    </Container>
  )
}

Format.propTypes = {
  pageContext: PropTypes.shape({
    page: PropTypes.shape({
      data: PropTypes.shape({
        intro_text: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
        description: PropTypes.PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
        headline: PropTypes.PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        eyebrow: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        topic: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
    }),
  }).isRequired,
}

export default Format
