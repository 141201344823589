import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ description, image, title, url }) => (
  <Helmet
    title={title}
    htmlAttributes={{
      lang: 'en',
    }}
    meta={[
      {
        property: 'description',
        content: description,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:url',
        content: url,
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:image',
        content: image,
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:description',
        content: description,
      },
      {
        name: 'twitter:image',
        content: image,
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:domain',
        content: 'https://medtechpulse.com',
      },
      {
        name: 'twitter:url',
        content: url,
      },
    ]}
  />
)

export default Seo
