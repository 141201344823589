/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as styles from './style.module.scss'
import classnames from 'classnames'

const SingleArticleTeaser = ({ data, uid, type }) => (
  <Link
    to={`/article/${data.type}/${uid}`}
    className={classnames(styles.link, { [styles[type]]: type })}
  >
    <div className={styles.imageContainer}>
      {data?.image_preview?.url ? (
        <div className={styles.image} style={{ backgroundImage: `url(${data.image_preview.url})` }} />
      ) :
        <div className={styles.image} style={{ backgroundImage: `url(${data.image.url})` }} />
      }
    </div>
    {data.themes && (
      <div className={styles.themes}>
        {data.themes.map(theme => (
          <h3 className={styles.theme}>{theme.theme}</h3>
        ))}
      </div>
    )}
    {data.teaser_label && (
      <h6 className={styles.label}>{data.teaser_label}</h6>
    )}
    <h4 className={styles.title}>
      {data.headline.text}
    </h4>
  </Link>
)

export default SingleArticleTeaser

SingleArticleTeaser.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    ),
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    themes: PropTypes.arrayOf(
      PropTypes.shape({
        theme: PropTypes.string.isRequired,
      }),
    ),
    topic_list: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    ),
    teaser_label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    mailchimp_archive_link: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
  uid: PropTypes.string.isRequired,
}
